<template>
  <v-card>
    <v-tabs v-model="activeTab" icons-and-text grow>
      <v-tab v-for="tab in tabs" :key="tab.id" :to="{ name: tab.route }" exact>
        {{ tab.name }}
        <v-icon>{{ tab.icon }}</v-icon>
      </v-tab>

      <v-tab-item
        v-for="tab in tabs"
        :key="tab.id"
        :value="getTabValue(tab.route)"
      ></v-tab-item>
    </v-tabs>

    <router-view></router-view>
  </v-card>
</template>

<script>
export default {
  name: "PanelAccount",
  data() {
    return {
      activeTab: null
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "account",
          route: "Account",
          name: this.$i18n.t("forms.account.title"),
          icon: "mdi-account-details"
        },
        {
          id: "security",
          route: "Security",
          name: this.$i18n.t("forms.security.title"),
          icon: "mdi-security"
        }
      ];
    }
  },
  methods: {
    getTabValue(route) {
      return this.$router.resolve({ name: route }).route.fullPath;
    }
  }
};
</script>
